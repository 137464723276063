window.onload = (e) => {
    document.body.classList.add('loaded');

    const svgs = document.getElementsByTagName('svg');
    for (i = 0; i < svgs.length; i++) {
        cropSVG(svgs[i]);
    }

    setNavHeight();
}

window.addEventListener('resize', debounce(function(e) {
    setNavHeight();
}))

function setNavHeight() {
    if (document.querySelector('main') != null) {
        var navMain = document.querySelector('main');
    } else if (document.querySelector('.tribe-common') != null) {
        var navMain = document.querySelector('.tribe-common');
    } else if (document.querySelector('#tribe-events-pg-template') != null) {
        var navMain = document.querySelector('#tribe-events-pg-template');
    }

    const navPrimary = document.getElementById('navPrimary');
    // const navMain = document.getElementById('content');
    var navHeight = navPrimary.offsetHeight + 'px';
    navMain.style.paddingTop = navHeight;
}

function cropSVG(svgEl) {
    const bbox = svgEl.getBBox();
    svgEl.setAttribute("viewBox", `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
}

var navIcon = document.getElementById('navIcon');
var navMenu = document.getElementById('navMenu');
navIcon.addEventListener('click',function(){
    this.classList.toggle('nav-close');
    navMenu.classList.toggle('nav-overlay');
});

function debounce(func) {
    var timer;
    return function(event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 250, event);
    };
}
